<template>
  <div>
    <div class="header"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="color: #fff">
          <h4 class="text-center">Pembayaran</h4>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-xl-12">
          <div class="card" style="border-radius: 15px">
            <div style="text-align: center; padding: 20px"></div>
            <div class="alert f-s-13">
              <div class="input-group">
                <input
                  v-model="search"
                  type="text"
                  class="form-control form-control"
                  placeholder="Masukkan NPWRD/NIK/NIB"
                />
                <span class="input-group-append">
                  <button
                    v-if="foundData"
                    class="btn btn bg-light"
                    @click="resetData"
                    variant="primary"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                  <button
                    class="btn btn bg-light"
                    @click="fetchData"
                    variant="primary"
                    :disabled="loading"
                  >
                    <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                    <i v-else class="fa fa-search"></i>
                  </button>
                  <button
                    class="btn btn bg-light"
                    @click="toggleCamera"
                    variant="primary"
                  >
                    <i class="fa fa-qrcode"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="showQrCode">
      <div
        class="row m-1 p-5"
        style="border-radius: 10px; background-color: #2b2b2b"
      >
        <StreamBarcodeReader
          style="margin: auto; border-radius: 10px; overflow: hidden"
          ref="scanner"
          @decode="onDecode"
          @loaded="onLoaded"
        ></StreamBarcodeReader>
      </div>
    </div>
    <div class="container" v-show="foundData">
      <div class="row">
        <div class="col-xl-12">
          <div class="card mb-3" style="border-radius: 15px">
            <div style="text-align: center; padding: 20px">
              <strong>Informasi Pelanggan</strong>
            </div>
            <div class="alert f-s-13">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <div class="card" style="border-radius: 10px; padding: 15px">
                    <div class="row mb-2">
                      <div class="col-6">Nama</div>
                      <div class="col-6 text-right">
                        <strong>
                          <LazyLoad
                            :data-to-display="detail.customer_name"
                          ></LazyLoad>
                        </strong>
                      </div>
                    </div>
                    <hr style="margin: 0px 0px 10px 0px" />
                    <div class="row">
                      <div class="col-6">NPWRD</div>
                      <div class="col-6 text-right">
                        <strong>
                          <LazyLoad :data-to-display="detail.npwrd"></LazyLoad>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-2">
                  <label class="mb-0">Jenis Kegiatan : </label>
                  <strong>
                    <LazyLoad
                      :data-to-display="detail.category_name"
                    ></LazyLoad>
                  </strong>
                </div>
                <div class="col-md-12" style="margin-bottom: -15px">
                  <label class="mb-0">Satuan : </label>
                  <strong>
                    <LazyLoad
                      :data-to-display="this.currencyFormat(detail.cost)"
                    ></LazyLoad>
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3" style="border-radius: 15px">
            <div class="alert f-s-13">
              <div class="row">
                <div class="col-md-12 mb-4" v-if="historyPayment.length > 0">
                  <h6>Riwayat Transaksi</h6>
                  <div class="bg-light f-s-12 text-dark rounded p-5">
                    <div
                      v-for="history in historyPayment"
                      :key="history.transaction_id"
                      class="d-flex align-items-center m-b-15"
                    >
                      <div class="text-dark">
                        <div>{{ history.additional_details }}</div>
                        <div class="text-dark">
                          {{ indonesianFormat(history.transaction_date) }}
                        </div>
                      </div>
                      <div class="ml-auto text-center">
                        <div class="f-s-13">
                          <span
                            data-animation="number"
                            :data-value="history.payment_amount"
                            >{{ currencyFormat(history.payment_amount) }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-4">
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 col-form-label push-right"
                      >Tahun
                    </label>
                    <div class="col-md-4">
                      <select
                        v-model="form.transaction_year"
                        v-on:change="resetMonth()"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.transaction_year,
                        }"
                      >
                        <option :value="currentYear - 1">
                          {{ currentYear - 1 }}
                        </option>
                        <option :value="currentYear">
                          {{ currentYear }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 col-form-label push-right"
                      >Periode Bayar
                    </label>
                    <div class="col-md-9">
                      <div class="input-group">
                        <select
                          v-model="period.first_month"
                          class="form-control col-md-5"
                          v-on:change="setFirstMonth($event)"
                          :class="{
                            'is-invalid': formValidate.additional_details,
                          }"
                        >
                          <option value="1" :disabled="payment.jan === '1'">
                            Januari
                          </option>
                          <option value="2" :disabled="payment.feb === '1'">
                            Februari
                          </option>
                          <option value="3" :disabled="payment.mar === '1'">
                            Maret
                          </option>
                          <option value="4" :disabled="payment.apr === '1'">
                            April
                          </option>
                          <option value="5" :disabled="payment.mei === '1'">
                            Mei
                          </option>
                          <option value="6" :disabled="payment.jun === '1'">
                            Juni
                          </option>
                          <option value="7" :disabled="payment.jul === '1'">
                            Juli
                          </option>
                          <option value="8" :disabled="payment.aug === '1'">
                            Agustus
                          </option>
                          <option value="9" :disabled="payment.sep === '1'">
                            September
                          </option>
                          <option value="10" :disabled="payment.okt === '1'">
                            Oktober
                          </option>
                          <option value="11" :disabled="payment.nov === '1'">
                            November
                          </option>
                          <option value="12" :disabled="payment.des === '1'">
                            Desember
                          </option>
                        </select>
                        <span class="input-group-append">
                          <span
                            class="input-group-text"
                            style="height: calc(1.5em + 0.875rem + 2px)"
                          >
                            -
                          </span>
                        </span>
                        <select
                          v-model="period.last_month"
                          class="form-control col-md-5"
                          v-on:change="setLastMonth($event)"
                          :class="{
                            'is-invalid': formValidate.additional_details,
                          }"
                        >
                          <option value="1" :disabled="payment.jan === '1'">
                            Januari
                          </option>
                          <option value="2" :disabled="payment.feb === '1'">
                            Februari
                          </option>
                          <option value="3" :disabled="payment.mar === '1'">
                            Maret
                          </option>
                          <option value="4" :disabled="payment.apr === '1'">
                            April
                          </option>
                          <option value="5" :disabled="payment.mei === '1'">
                            Mei
                          </option>
                          <option value="6" :disabled="payment.jun === '1'">
                            Juni
                          </option>
                          <option value="7" :disabled="payment.jul === '1'">
                            Juli
                          </option>
                          <option value="8" :disabled="payment.aug === '1'">
                            Agustus
                          </option>
                          <option value="9" :disabled="payment.sep === '1'">
                            September
                          </option>
                          <option value="10" :disabled="payment.okt === '1'">
                            Oktober
                          </option>
                          <option value="11" :disabled="payment.nov === '1'">
                            November
                          </option>
                          <option value="12" :disabled="payment.des === '1'">
                            Desember
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="customer_name"
                      >Jumlah Bayar
                    </label>
                    <div class="col-md-8 align-middle">
                      <h5 class="mt-2">
                        Rp. {{ this.currencyFormat(form.payment_amount) }}
                      </h5>
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label
                      class="col-md-3 col-form-label push-right"
                      for="customer_name"
                      >Keterangan
                    </label>
                    <div class="col-md-8">
                      <h6 class="mt-2">{{ form.additional_details }}</h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-12" style="margin-bottom: -15px">
                  <button
                    @click="processPayment"
                    type="button"
                    class="btn btn-success btn-block"
                    :disabled="this.loading"
                  >
                    <i
                      v-if="this.loading"
                      class="fas fa-circle-notch fa-spin"
                    ></i>
                    Proses Bayar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import PageOptions from "../../config/PageOptions.vue";
import moment from "moment";
import LazyLoad from "../../components/loader/LazyLoad.vue";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  components: {
    StreamBarcodeReader,
    LazyLoad,
  },
  data() {
    return {
      isCameraPaused: false,
      showQrCode: false,
      isScrolled: false,
      notImage: false,
      search: "",
      detail: {
        customer_id: "",
        npwrd: "",
        identity_number: "",
        customer_name: "",
        customer_address: "",
        district: "",
        village: "",
        hamlet: "",
        neighbourhood: "",
        customer_categories: "",
        created_by: "",
        created_at: "",
        created_name: "",
      },
      payment: {
        jan: "",
        feb: "",
        mar: "",
        apr: "",
        mei: "",
        jun: "",
        jul: "",
        aug: "",
        sep: "",
        okt: "",
        nov: "",
        des: "",
      },
      formValidate: [],
      form: {
        transaction_year: moment().format("YYYY"),
        transaction_date: moment().format("YYYY-MM-DD"),
        customer: "",
        payment_amount: "",
        payment_method: "Online",
        additional_details: "",
        checking_account: "",
      },
      period: {
        first_month: "",
        last_month: "",
      },
      historyPayment: [],
      monthArray: [],
      currentYear: moment().format("YYYY"),
      loading: false,
      foundData: false,
    };
  },
  computed: {
    rwrtData() {
      return `${this.detail.hamlet} / ${this.detail.neighbourhood}`;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      formData.append("first_month", this.period.first_month);
      formData.append("last_month", this.period.last_month);
      axios
        .post("/v1/transactions/online/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: "Pembayaran Sukses",
              text: "Terimakasih Telah Melakukan Pembayaran Retribusi Sampah Di Pakagali",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#FE8E00",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.value) {
                window.location.assign("https://pakagali.com");
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    processPayment() {
      let vm = this;
      this.loading = true;
      const formData = new FormData();
      formData.append("first_month", this.period.first_month);
      formData.append("last_month", this.period.last_month);
      formData.append("npwrd", this.detail.npwrd);
      axios
        .post("/v1/midtrans/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            // console.log(response.data.token);
            const script = document.createElement("script");
            script.src = process.env.VUE_APP_MID_URL;
            script.setAttribute("data-client-key", process.env.VUE_APP_MID_KEY);
            script.onload = function () {
              window.snap.pay(response.data.token, {
                onSuccess: function () {
                  vm.postData();
                },
                onError: function () {
                  Swal.fire({
                    title: "Info",
                    text: "Transaksi Gagal",
                    icon: "info",
                    showCancelButton: false,
                    showConfirmButton: true,
                  });
                },
                // onClose: function () {
                //   alert("closed the popup");
                // },
              });
            };
            document.body.appendChild(script);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    toggleCamera() {
      if (this.showQrCode) {
        this.showQrCode = false;
        this.$refs.scanner.codeReader.stream
          .getTracks()
          .forEach(function (track) {
            track.stop();
          });
      } else {
        this.showQrCode = true;
      }
    },
    onDecode(text) {
      console.log(`Decode text from QR code is ${text}`);
      this.search = text;
      this.fetchData();
      this.$refs.scanner.codeReader.stream
        .getTracks()
        .forEach(function (track) {
          track.stop();
        });
    },
    onLoaded() {
      console.log(`Ready to start scanning barcodes`);
    },
    fetchData() {
      axios
        .get("/v1/customers/npwrd/" + this.search)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          this.form.customer = response.data.data.customer_id;
          this.historyPayment = response.data.history;
          this.payment = response.data.payment;
          this.foundData = true;
          this.showQrCode = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "Info",
            text: "Anda belum terdaftar, silahkan mendaftarkan diri di loket pakagali pada kelurahan masing-masing",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: true,
          });
          for (let key in this.detail) {
            this.detail[key] = "";
          }
          this.foundData = false;
          // setTimeout(() => {
          //   Swal.close();
          //   for (let key in this.detail) {
          //     this.detail[key] = "";
          //   }
          //   this.foundData = false;
          // }, 3000);
          console.log(error);
          this.error = error.message;
        });
    },
    resetData() {
      for (let key in this.detail) {
        this.detail[key] = "";
      }
      this.search = "";
      this.foundData = false;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    timeStampFormat(dateTime) {
      return moment.unix(dateTime).format("D MMMM YYYY");
    },
    paymentStatus(param) {
      if (param == 1) {
        return "<i class='fa fa-check-circle'></i>";
      } else {
        return "Belum Lunas";
      }
    },
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    resetMonth() {
      this.period.first_month = "";
      this.period.last_month = "";
      this.form.payment_amount = "";
      this.form.additional_details = "";
    },
    setFirstMonth(event) {
      // var currentDate = new Date();
      // var currentYear = currentDate.getFullYear();
      let currentYear = this.form.transaction_year;

      this.period.first_month = event.target.value;
      this.monthArray.push(parseInt(this.period.first_month));

      var monthNames = {
        1: "Januari",
        2: "Februari",
        3: "Maret",
        4: "April",
        5: "Mei",
        6: "Juni",
        7: "Juli",
        8: "Agustus",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Desember",
      };
      let first_month = parseInt(this.period.first_month);
      let last_month = parseInt(this.period.last_month);

      if (!last_month) {
        this.form.additional_details =
          monthNames[first_month] + " " + currentYear;
        this.monthArray = [];
        this.monthArray.push(first_month);
      }

      if (first_month > last_month) {
        this.form.additional_details =
          monthNames[first_month] + " " + currentYear;
        this.period.last_month = "";
        this.monthArray = [];
        this.monthArray.push(first_month);
      }

      if (first_month < last_month) {
        this.form.additional_details =
          monthNames[first_month] +
          " s/d " +
          monthNames[last_month] +
          " " +
          currentYear;

        this.monthArray = [];
        for (var i = first_month; i <= last_month; i++) {
          this.monthArray.push(i);
        }
      }

      if (first_month == last_month) {
        this.form.additional_details =
          monthNames[first_month] + " " + currentYear;
        this.period.last_month = "";
        this.monthArray = [];
        this.monthArray.push(first_month);
      }

      this.form.payment_amount = this.monthArray.length * this.detail.cost;
    },
    setLastMonth(event) {
      // var currentDate = new Date();
      // var currentYear = currentDate.getFullYear();
      let currentYear = this.form.transaction_year;
      this.period.last_month = event.target.value;

      let first_month = parseInt(this.period.first_month);
      let last_month = parseInt(this.period.last_month);

      var monthNames = {
        1: "Januari",
        2: "Februari",
        3: "Maret",
        4: "April",
        5: "Mei",
        6: "Juni",
        7: "Juli",
        8: "Agustus",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Desember",
      };

      if (!last_month) {
        this.form.additional_details =
          monthNames[first_month] + " " + currentYear;
        this.monthArray = [];
        this.monthArray.push(first_month);
      }

      if (first_month > last_month) {
        this.form.additional_details =
          monthNames[first_month] + " " + currentYear;
        this.period.last_month = "";
        this.monthArray = [];
        this.monthArray.push(first_month);
      }

      if (first_month < last_month) {
        this.form.additional_details =
          monthNames[first_month] +
          " s/d " +
          monthNames[last_month] +
          " " +
          currentYear;
        this.monthArray = [];
        for (var i = first_month; i <= last_month; i++) {
          this.monthArray.push(i);
        }
      }

      if (first_month == last_month) {
        this.form.additional_details =
          monthNames[first_month] + " " + currentYear;
        this.period.last_month = "";
        this.monthArray = [];
        this.monthArray.push(first_month);
      }

      this.form.payment_amount = this.monthArray.length * this.detail.cost;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="css" scoped>
@font-face {
  font-family: "Saira";
  src: url("../../assets/font/saira.ttf") format("truetype");
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background-color: #e94f07;
  /* color: #fff; */
  border-radius: 0px 0px 30px 30px;
  margin-bottom: -130px;
  z-index: 0;
}
.container {
  padding: 20px;
}
@import "/css/front-end/app.min.css";

.footers {
  background-color: #2b2b2b;
  color: #fff;
  position: sticky;
  top: 100%;
}
.copy {
  border-top: solid 0.15em #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: light;
  font-size: 14px;
  padding: 20px;
  margin: 0 3em;
}
.card-menu {
  background-color: #fdfdfd;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 40vh;
}
</style>